import ImageConversion from 'image-conversion';

export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result)
    };

    fileReader.onerror = (err) => {
      reject(err)
    };
  })
}

export const compressImage = async (image, { width = 1000, quality = 0.8 } = {}) => {
  const imageType = image.split(';')[0].replace('data:', '');
  const imageLoaded = await ImageConversion.dataURLtoFile(image);
  const compressedImage = await ImageConversion.compress(imageLoaded, {
    quality,
    type: imageType,
    width,
  });
  const finalImage = await ImageConversion.filetoDataURL(compressedImage);
  return finalImage;
}

